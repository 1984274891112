<div class="no-results-message" *ngIf="!haveNotifications()">
    <mat-icon class="icon" svgIcon="notifications"></mat-icon>
    <div class="text">
        <div class="main" trans>Hang tight!</div>
        <div class="secondary" trans>Notifications will start showing up here soon.</div>
        <a routerLink="/notifications/settings" (click)="closePanel()" class="notif-settings-link"
           *ngIf="settings.get('notif.subs.integrated')" trans>Notification Settings</a>
    </div>
</div>

<ng-container></ng-container>
<ng-container *ngFor="let notification of notifications; let i = index; trackBy: trackByFn">
    <div class="group-header" *ngIf="notifications[i - 1]?.time_period !== notification.time_period" trans>{{notification.time_period}}</div>
    <div class="notification"
        [class.compact]="compact"
        [class.unread]="!notification.read_at"
        [class.has-action]="notification.data.mainAction"
        [class.warning]="notification.data.warning"
        [title]="notification.data.mainAction?.label || ''"
        (click)="performAction(notification.data.mainAction, notification, $event)"
    >
        <image-or-icon class="inherit-size" [src]="notification.data.image"></image-or-icon>
        <div class="middle-column">
            <div class="line"
                 [class.primary-line]="index === 0"
                 [class.secondary-line]="index > 0"
                 (click)="performAction(line.action, notification, $event)"
                 [title]="line.action?.label || ''"
                 *ngFor="let line of notification.data.lines; index as index"
                 [class.has-action]="line.action"
                 [innerHTML]="line.content"
            ></div>
        </div>
        <div class="right-column" *ngIf="!compact">{{notification.relative_created_at}}</div>
    </div>
</ng-container>
